import React from "react";
import StoriesItem from "./StoriesItem";
const Stories = ({ data }) => {
  return (
    <>
      <div className="block lg:hidden">
        <div className="container">
          <div className="overflow-x-auto flex flex-nowrap relative pb-4">
            {data &&
              data.map((item, key) => (
                <StoriesItem
                  url={item.url}
                  title={item.baslik}
                  icon={item.resim}
                  animation={item.animation}
                  index={key}
                  key={key}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stories;
