import Link from "next/link";
import React from "react";
// import Lottie from "lottie-react";
import Remote, { Blur } from "./LoaderRemote";
import Image from "next/image";
import themes from "../themes/themes";

const StoriesItem = ({ url, title, icon, index, animation }) => {
  //storyItemBorderColor
  const style = {
    height: 50,
    width: 50,
  };
  return (
    <Link href={url}>
      <a className="flex flex-col items-center mr-2">
        <div
          className={`w-[4.25rem] h-[4.25rem] flex items-center justify-center border-2 rounded-full ${themes.storyItemBorderColor}`}
        >
          {/* <Lottie animationData={JSON.parse(animation)} style={style} /> */}
          {icon && (
            <Image
              alt={title}
              src={icon}
              loader={Remote}
              width={48}
              height={48}
              objectFit={"contain"}
              objectPosition={"center"}
              placeholder={"blur"}
              blurDataURL={Blur(icon)}
            />
          )}
        </div>
        <div className="flex flex-col text-center font-bold text-[0.688rem] mt-1">
          <span>{title}</span>
        </div>
      </a>
    </Link>
  );
};

export default StoriesItem;
